import React, {
    useMemo, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { navigate, getData } from '../utils/router';
import UserContext, { PusherContext, UserProfileContext } from '../utils/getContext';
import { checkIsAdmin } from '../utils/memo';
import { usePusher } from '../utils/pusher.utills';

import { useRouter } from 'next/router';
import T from '../../common/components/T';
import {
    Div, svgSprite, Portal, P
} from '../../hybrid/wrappers';
import BlockedPageModal from '../components/BlockedPageModal';

const ProtectedAdmin = ({ children, role = [] }) => {
    const {
        userToken, lang, roles
    } = useContext(UserContext);
    const [userAuthToken, setUserAuthToken] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const { pusherClient } = usePusher(loaded && userToken, lang);
    const hasRoles = useMemo(() => roles.some((item) =>
        [...role, 'admin'].some((r) => item.name.includes(r))), [roles, role]);
    useEffect(() => {
        if (!userToken) {
            navigate('/[lang]/authentication/[type]', '/authentication/login', lang);
        } else {
            (async () => {
                const data = await checkIsAdmin(userToken);
                if (data) {
                    if (roles.length){
                        if (hasRoles){
                            setUserAuthToken(true);
                        } else {
                            const isFinanceManager = roles.some(item => item.name === 'finance::manager');
                            const isTranlator = roles.some(item => item.name === 'translator');
                            const isLogist = roles.some(item => item.name === 'logistician' || item.name === 'shop_manager');
                            if (isFinanceManager) {
                                navigate('/[lang]/admin/finance', '/admin/finance', lang)
                            } else if (isTranlator) {
                                navigate('/[lang]/admin/languages', '/admin/languages', lang);
                            } else if (isLogist) {
                                navigate('/[lang]/admin', '/admin', lang);
                            } else {
                                navigate('/[lang]/error/[type]', '/error/403', lang);
                            }
                        }
                    }
                    setLoaded(true)
                } else {
                    navigate('/[lang]/error/[type]', '/error/403', lang);
                }
            })();
        }
    }, [roles, hasRoles, lang, userToken]);


    const [blockPageData, setBlockPageData] = useState(false);
    const router = useRouter();
    const getIdPage = useMemo(() => {
        switch (router.route) {
        case '/[lang]/admin/menu':
            return 8
        case '/[lang]/admin/pagemanagement':
            return 9
        case '/[lang]/admin/news':
            return 10
        case '/[lang]/admin/faq':
            return 11
        case '/[lang]/admin/departments':
            return 12
        case '/[lang]/admin/user':
            return 13
        case '/[lang]/admin/verification':
            return 14
        case '/[lang]/admin/store/product':
            return 15
        case '/[lang]/admin/store/package':
            return 16
        case '/[lang]/admin/store/order':
            return 17
        case router.route.includes('admin/finance'):
            return 18
        case router.route.includes('admin/languages'):
            return 19
        case '/[lang]/admin/communication':
            return 20
        case '/[lang]/admin/settings':
            return 21
        case '/[lang]/admin/seo':
            return 22
        case '/[lang]/admin/extras':
            return 23
        case '/[lang]/admin/extras/files':
            return 24
        case '/[lang]/admin/register':
            return 25
        case '/[lang]/admin/captcha':
            return 26
        }
    }, [router]);

    useEffect(() => {
        (async () => {
            const dataPage = await getData('/api/v1/education/blocked-page', userToken, lang);
            let blockedPages = [];
            if (dataPage?.data?.length) {
                blockedPages = dataPage.data.filter(i => i.closed)
            }
            if (blockedPages.length) {
                blockedPages.map(i => {
                    if (i.id == getIdPage) {
                        setBlockPageData(i)
                    }
                })
            }
        })();
    }, [userToken, lang, getIdPage]);


    if (!userToken || !hasRoles) return null;
    return (
        <PusherContext.Provider value={{ pusherClient }}>
            <UserProfileContext.Provider value={{ loaded: userAuthToken, adminPage: true }}>
                <Div styles={{ 'min-height': '100vh' }}>{children}</Div>
                {
                blockPageData?.id ? <BlockedPageModal blockPageData={blockPageData} /> : null}
            </UserProfileContext.Provider>
        </PusherContext.Provider>
    );
};

ProtectedAdmin.propTypes = { role: PropTypes.array };

export default ProtectedAdmin;