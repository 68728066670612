

const defaultColors = {
    white: '255, 255, 255',
    black: '0, 0, 0',
    dark: '15, 19, 36',
    mainColorText: '35, 31, 32',
    greyLight: '240, 241, 245',
    greyExtraDark: '143, 143, 171',
    blue: '44, 65, 53', //'67, 95, 197', darkGreen
    blueDark: '35, 31, 32', //'40, 35, 89',
    blueDeep: '35, 31, 32', //'73, 109, 247', darkBrown
    purple: '158, 108, 186', //'161, 46, 225',
    purpleDeep: '100, 65, 119', //'160, 67, 192',
    red: '255, 69, 58',
    redLight: '237, 68, 100',
    redDark: '217, 71, 99',
    orangeDeep: '158, 138, 106', //'249, 137, 55',
    orange: '189, 165, 127', //'250, 176, 49',
    green: '107, 145, 123', //'42, 185, 116',
    greenDeep: '44, 65, 53', //'52, 199, 89',
    greenLight: '16, 181, 106'
}
const colors = {
    main: { ...defaultColors },
    office: { ...defaultColors },
    admin: { ...defaultColors }
}
const getCustomTransparentFunc = (part) => (color, opacity) => `rgba(${colors[part][color]}, ${opacity})`

const variables = (part) => {
    const customTransparent = getCustomTransparentFunc(part)
    return {
        calcColor: customTransparent,

        mainColorAccent: '#BCA57F',
        mainColorAccentHover: '#2C4135',

        // White / black variables
        whiteColor: customTransparent('white', '1'),
        whiteLessTransparentColor: customTransparent('white', '0.2'),
        whiteTransparentColor: customTransparent('white', '0.11'),
        darkDeepColor: 'rgba(14, 12, 32, 1)',
        whiteMoreTransparentColor: customTransparent('white', '0.5'),
        whiteExtraTransparentColor: customTransparent('white', '0.7'),
        blackColor: customTransparent('black', '1'),
        blackHalfTransparentColor: customTransparent('black', '0.5'),
        blackTransparentColor: customTransparent('black', '0.25'),
        blackExtraTransparentColor: customTransparent('black', '0.14'),
        blackSuperTransparentColor: customTransparent('black', '0.06'),

        // Dark variables
        darkBlueColor: customTransparent('blueDark', '1'), //'rgba(37, 39, 51, 1)',
        darkColor: customTransparent('dark', '1'),
        mainColorText: customTransparent('mainColorText', '1'),
        darkMiddleTransparentColor: customTransparent('dark', '0.8'),
        darkTransparentColor: customTransparent('dark', '0.3'),
        darkHalfTransparentColor: customTransparent('dark', '0.6'),
        darkExtraTransparentColor: customTransparent('dark', '0.15'),
        darkMoreTransparentColor: customTransparent('dark', '0.04'),
        darkSuperTransparentColor: customTransparent('dark', '0.06'),
        darkGreyColor: 'rgba(40, 44, 52, 1)',
        darkExtraBlueColor: 'rgba(28, 30, 38, 1)',

        // Blue variables
        blueColor: customTransparent('blue', '1'),
        blueExtraLightColor: 'rgba(176, 193, 255, 1)',
        blueDarkColor: customTransparent('blueDark', '1'),
        blueBackgroundLight: 'rgba(245, 248, 255, 1)',
        blueDeepAddColor: 'rgba(107, 135, 239, 1)',
        blueSkyColor: 'rgba(33, 175, 206, 1)',
        blueSkyTransparentColor: 'rgba(33, 175, 206, 0.12)',
        blueSkyHalfTransparentColor: 'rgba(3, 155, 229, 0.5)',
        blueSeaColor: 'rgba(73, 216, 247, 0.75)',
        blueDeepColor: 'rgba(54, 202, 235, 1)',
        blueDeepLightColor: customTransparent('blueDeep', '1'),
        blueDeepLightHalfTransparentColor: customTransparent('blueDeep', '0.75'),
        blueDeepLightTransparentColor: customTransparent('blueDeep', '0.15'),
        blueLightColor: 'rgba(70, 128, 194, 1)',
        blueTotalGray: 'rgba(63, 66, 80, 1)',
        blueTotalGrayTransparent: 'rgba(63, 66, 80, 0.2)',
        blueHalfTransparentColor: customTransparent('blue', '0.5'),
        blueTransparentColor: customTransparent('blue', '0.15'),
        blueColorTransparent: customTransparent('blue', '0.12'),
        blueExtraTransparentColor: customTransparent('blue', '0.06'),
        blueDarkTransparentColor: customTransparent('blueDark', '0.12'),
        blueDarkExtraTransparentColor: customTransparent('blueDark', '0.06'),
        bluePurpleColor: 'rgba(102, 92, 172, 1)',
        blueGradient: `linear-gradient(108.49deg, rgba(44, 65, 53, 1) 0%, ${customTransparent('blue', '1')} 100%)`, //`linear-gradient(108.49deg, rgba(107, 135, 239, 1) 0%, ${customTransparent('blue', '1')} 100%)`,
        blueGradient1: `linear-gradient(108.49deg, rgba(44, 65, 53, 1) 0%, ${customTransparent('blue', '1')} 100%)`, //`linear-gradient(108.49deg, rgba(121, 143, 223, 1) 0%, ${customTransparent('blue', '1')} 100%)`,

        // Grey variables
        greyDarkColor: 'rgba(149, 148, 149, 1)',
        greyDarkTransparentColor: 'rgba(12, 19, 36, 0.12)',
        greyTransparentColor: 'rgba(143, 143, 172, 0.12)',
        greyShadowColor: 'rgba(20, 21, 22, 0.15)',
        greySuperDarkColor: 'rgba(111, 113, 124, 1)',
        greySuperDarkAltColor: 'rgba(138, 138, 138, 1)',
        greyUltraLightColor: 'rgba(244, 244, 247, 1)',
        greyExtraDarkColor: customTransparent('greyExtraDark', '1'),
        greyExtraDarkColorTransparent: customTransparent('greyExtraDark', '0.1'),
        greyExtraDarkHalfTransparentColor: customTransparent('greyExtraDark', '0.6'),
        greyExtraDarkMHalfTransparentColor: customTransparent('greyExtraDark', '0.5'),
        greyExtraDarkTransparentColor: customTransparent('greyExtraDark', '0.08'),
        greySuperExtraDarkTransparentColor: customTransparent('greyExtraDark', '0.05'),
        greyExtraDarkExtraTransparentColor: customTransparent('greyExtraDark', '0.01'),
        greyDeepExtraDarkColor: 'rgba(199, 204, 218, 1)',
        greyDeepDarkColor: 'rgba(209, 215, 226, 1)',
        greyDeepLightColor: 'rgba(206, 206, 218, 1)',
        greyDeepColor: 'rgba(204, 204, 204, 1)',
        greyDeepExtraLightColor: 'rgba(229, 229, 233, 1)',
        greyDeepAltExtraLightColor: 'rgba(233, 233, 238, 1)',
        greyBlueDarkColor: 'rgba(198, 217, 255, 1)',
        greyBlueColor: 'rgba(244, 235, 220, 1)', //'rgba(201, 234, 218, 1)',
        greyBlueLightColor: 'rgba(209, 236, 255, 1)',
        greyBlueSuperLightColor: 'rgba(212, 241, 227, 1)',
        greyMainColor: 'rgba(231, 231, 233, 1)',
        greyColor: 'rgba(239, 238, 236, 1)', //'rgba(238, 240, 250, 1)',
        greyTransparentHalfColor: 'rgba(244, 235, 220, 0.4)',
        greyLightColor: customTransparent('greyLight', '1'),
        greyExtraLightColor: 'rgba(246, 246, 248, 1)',
        greySuperLightColor: 'rgba(249, 244, 237, 1)', //'rgba(247, 248, 253, 1)',
        greyGreenColor: 'rgba(218, 251, 199, 1)',
        greyWhiteColor: 'rgba(254, 227, 255, 1)',
        beigeColor: 'rgba(255, 237, 189, 1)',
        greyExtra: 'rgba(159, 161, 167, 1)',
        greySkeletonLoader: 'rgba(232, 234, 238, 1)',
        greySkeletonButton: 'rgba(220, 221, 225, 1)',

        // Red variables
        redColor: customTransparent('red', '1'),
        redTransparentColor: customTransparent('red', '0.03'),
        redHalfTransparentColor: customTransparent('red', '0.7'),
        redLightColor: customTransparent('redLight', '1'),
        redExtraLightColor: 'rgba(255, 249, 249, 1)',
        redLightTransparentColor: customTransparent('redLight', '0.12'),
        redDarkColor: customTransparent('redDark', '1'),
        redDarkColorTransparent: customTransparent('redDark', '0.1'),
        redDarkExtraTransparent: customTransparent('redDark', '0.05'),
        redDeepColor: 'rgba(255, 0, 0, 1)',
        redGradient: `linear-gradient(90deg, ${customTransparent('redLight', '1')} 0%, rgba(243, 146, 164, 1) 100%)`,
        redBackground: customTransparent('redDark', '0.12'),

        // Purple variables
        purpleLightColor: 'rgba(113, 96, 235, 1)',
        purpleLightTransparentColor: 'rgba(113, 96, 235, 0.12)',
        purpleDarkColor: 'rgba(99, 42, 119, 1)',
        purpleColor: customTransparent('purple', '1'),
        purpleTransparentColor: customTransparent('purple', '0.15'),
        purpleDeepColor: customTransparent('purpleDeep', '1'),
        purpleDeepTransparentColor: customTransparent('purpleDeep', '0.12'),
        purpleDeepExtraTransparentColor: customTransparent('purpleDeep', '0.075'),
        purpleGradient: `linear-gradient(90deg, ${customTransparent('purple', '1')} 0%, rgba(220, 157, 255, 1) 100%)`,

        // Orange variables
        yellowColor: 'rgba(255, 199, 18, 1)',
        orangeDeepColorLight: customTransparent('orangeDeep', '1'),
        orangeDeepTransparentColorLight: customTransparent('orangeDeep', '0.12'),
        orangeDeepExtraTransparentColorLight: customTransparent('orangeDeep', '0.05'),
        orangeColor: customTransparent('orange', '1'),
        orangeAltColor: 'rgba(247, 147, 26, 1)',
        orangeDarkColor: 'rgba(158, 138, 106, 1)', //'rgba(238, 148, 42, 1)',
        orangeLightColor: 'rgba(189, 165, 127, 1)', //'rgba(241, 169, 84, 1)',
        orangeDeepColor: 'rgba(254, 133, 0, 1)',
        orangeHalfTransparentColor: 'rgba(189, 165, 127, 0.4)',
        orangeTransparentColor: 'rgba(189, 165, 127, 0.12)',
        orangeExtraTransparentColor: customTransparent('orange', ' 0.05'),
        orangeGradient: 'linear-gradient(108.49deg, rgba(189, 165, 127, 1) 0%, rgba(189, 165, 127, 1) 100%)',
        orangeGradient1: `linear-gradient(108.49deg, rgba(189, 165, 127, 1) 0%, ${customTransparent('orangeDeep', '1')} 100%)`,

        // Brown variables
        brownColor: 'rgba(160, 126, 102, 1)',

        // Green variables
        greenRegisterColor: 'rgba(60, 226, 77, 0.75)',
        greenDeepColor: customTransparent('greenDeep', '1'),
        greenDeepDarkColor: 'rgba(76, 175, 80, 1)',
        greenExtraTransparentColor: customTransparent('greenDeep', '0.08'),
        greenDeepTransparentColor: customTransparent('greenDeep', '0.05'),
        greenColor: customTransparent('green', '1'),
        greenGradientLightColor: 'rgba(116, 221, 170, 1)',
        greenLightColor: customTransparent('greenLight', '1'),
        greenLightHalfTransparentColor: customTransparent('greenLight', '0.75'),
        greenLightTransparentColor: customTransparent('greenLight', '0.2'),
        greenLightExtraTransparentColor: customTransparent('greenLight', '0.12'),
        greenHalfTransparentColor: customTransparent('greenLight', '0.4'),
        greenTransparentColor: customTransparent('green', '0.12'),
        greenExtraSuperTransparentColor: customTransparent('green', '0.075'),
        greenSuperExtraTransparentColor: customTransparent('green', '0.03'),
        greenGradient: `linear-gradient(90deg, ${customTransparent('green', '1')} 0%, rgba(147, 224, 187, 1) 100%)`,
        greenGradient1: `linear-gradient(90deg, ${customTransparent('green', '1')} 0%, rgba(29, 157, 96, 1) 100%)`,


        // Backgrounds variables
        // mainWrapperMenuBackground: `linear-gradient(122.71deg, ${customTransparent('blueDeep', '1')} 0%, rgba(73, 216, 247, 1) 100%)`,
        // mainWrapperWebBackground: `linear-gradient(122.71deg, ${customTransparent('blueDeep', '0.75')} 0%, rgba(73, 216, 247, 0.75) 100%)`,
        // mainWrapperWebRegisterBackground: `linear-gradient(108.49deg, ${customTransparent('greenLight', '0.75')} 0%, rgba(60, 226, 77, 0.75) 100%)`,
        // mainWrapperWebRestoreBackground: `linear-gradient(108.49deg, ${customTransparent('purple', '0.75')} 0%, rgba(255, 63, 97, 0.75) 100%)`,
        mainWrapperMenuBackground: `linear-gradient(122.71deg, ${customTransparent('blueDeep', '1')} 0%, rgba(35, 31, 32, 1) 100%)`,
        mainWrapperWebBackground: `linear-gradient(122.71deg, ${customTransparent('blueDeep', '1')} 0%, rgba(35, 31, 32, 0.75) 100%)`,
        mainWrapperWebRegisterBackground: `linear-gradient(108.49deg, ${customTransparent('greenDeep', '1')} 0%, rgba(44, 65, 53, 0.75) 100%)`,
        mainWrapperWebRestoreBackground: `linear-gradient(108.49deg, ${customTransparent('greenDeep', '1')} 0%, rgba(44, 65, 53, 0.75) 100%)`,

        marketingProgressGradient: 'linear-gradient(90deg, rgba(230, 235, 255, 1) 0%, rgba(169, 185, 245, 1) 100%)'
    }
}
export const rankColors = [
    {
        back: '#EEEDEE',
        color: '#282359',
        gradient: 'linear-gradient(89.82deg, #565656 0.15%, #A9A9A9 99.85%)'
    },
    {
        back: '#E6EADA',
        color: '#C8DB92',
        gradient: 'linear-gradient(89.82deg, #829844 0.15%, #C8DB92 99.85%)'
    },
    {
        back: '#DCECF1',
        color: '#8ACFE4',
        gradient: 'linear-gradient(89.82deg, #448CA3 0.15%, #8ACFE4 99.85%)'
    },
    {
        back: '#E1DAD6',
        color: '#C09378',
        gradient: 'linear-gradient(89.82deg, #6E4832 0.15%, #C09378 99.85%)'
    },
    {
        back: '#E0E1E4',
        color: '#A5B5CD',
        gradient: 'linear-gradient(89.82deg, #465874 0.15%, #A5B5CD 99.85%)'
    },
    {
        back: '#FBF2D1',
        color: '#FFD28C',
        gradient: 'linear-gradient(89.82deg, #EC9001 0.15%, #FFD28C 99.85%)'
    },
    {
        back: '#F4D1E2',
        color: '#FC84B1',
        gradient: 'linear-gradient(89.82deg, #CB2A66 0.15%, #FC84B1 99.85%)'
    },
    {
        back: '#CFD1F4',
        color: '#837EF8',
        gradient: 'linear-gradient(89.82deg, #332DC0 0.15%, #837EF8 99.85%)'
    },
    {
        back: '#D4E8DF',
        color: '#6DD8A9',
        gradient: 'linear-gradient(89.82deg, #298F62 0.15%, #6DD8A9 99.85%)'
    },
    {
        back: '#DAD9FB',
        color: '#9FBCFF',
        gradient: 'linear-gradient(89.82deg, #3064DF 0.15%, #9FBCFF 99.85%)'
    },
    {
        back: '#FBD4D6',
        color: '#F89898',
        gradient: 'linear-gradient(90deg, #C02121 0.51%, #F89898 100.51%)'
    },
    {
        back: '#F7DEFD',
        color: '#E48FF8',
        gradient: 'linear-gradient(90deg, #871AA1 2.07%, #E48FF8 100.51%)'
    }
]

const chartStatsColors = (part) => ({
    chartStatsColor_1: variables(part).blueColor,
    chartStatsColor_2: variables(part).purpleDeepColor,
    chartStatsColor_3: variables(part).orangeDeepColorLight,
    chartStatsColor_4: variables(part).greenColor,
    chartStatsColor_5: variables(part).blueSkyColor,
    chartStatsColor_6: variables(part).orangeColor,
    chartStatsColor_7: variables(part).greyDeepExtraDarkColor
})
const officeVolumesColor = (part) => ({
    officeVolumesColor_0: variables(part).redLightColor,
    officeVolumesColorTransparent_0: variables(part).redLightTransparentColor,
    officeVolumesColor_1: variables(part).purpleColor,
    officeVolumesColorTransparent_1: variables(part).calcColor('purple', '0.12'),
    officeVolumesColor_2: variables(part).blueColor,
    officeVolumesColorTransparent_2: variables(part).blueColorTransparent,
    officeVolumesColor_3: variables(part).orangeDeepColor,
    officeVolumesColorTransparent_3: variables(part).orangeTransparentColor,
    officeVolumesColor_4: variables(part).greenColor,
    officeVolumesColorTransparent_4: variables(part).greenTransparentColor,
    officeVolumesColor_5: variables(part).blueSkyColor,
    officeVolumesColorTransparent_5: variables(part).blueSkyTransparentColor,
    officeVolumesColor_6: variables(part).orangeDeepColor,
    officeVolumesColorTransparent_6: variables(part).orangeTransparentColor,
    officeVolumesColorGroup_0: variables(part).redLightColor,
    officeVolumesColorGroupTransparent_0: variables(part).redLightTransparentColor,
    officeVolumesColorGroup_1: variables(part).redLightColor,
    officeVolumesColorGroupTransparent_1: variables(part).redLightTransparentColor,
    officeVolumesColorGroup_2: variables(part).purpleColor,
    officeVolumesColorGroupTransparent_2: variables(part).calcColor('purple', '0.12'),
    officeVolumesColorGroup_3: variables(part).purpleColor,
    officeVolumesColorGroupTransparent_3: variables(part).calcColor('purple', '0.12'),
    officeVolumesColorGroup_4: variables(part).blueColor,
    officeVolumesColorGroupTransparent_4: variables(part).blueColorTransparent,
    officeVolumesColorGroup_5: variables(part).blueColor,
    officeVolumesColorGroupTransparent_5: variables(part).blueColorTransparent,
    officeVolumesColorGroup_6: variables(part).orangeDeepColor,
    officeVolumesColorGroupTransparent_6: variables(part).orangeTransparentColor,
    officeVolumesColorGroup_7: variables(part).orangeDeepColor,
    officeVolumesColorGroupTransparent_7: variables(part).orangeTransparentColor,
    officeVolumesColorGroup_8: variables(part).greenColor,
    officeVolumesColorGroupTransparent_8: variables(part).greenLightTransparentColor,
    officeVolumesColorGroup_9: variables(part).greenColor,
    officeVolumesColorGroupTransparent_9: variables(part).greenLightTransparentColor,
    officeVolumesColorGroup_10: variables(part).greyDarkColor,
    officeVolumesColorGroupTransparent_10: variables(part).greyDarkTransparentColor,
    officeVolumesColorGroup_11: variables(part).greyDarkColor,
    officeVolumesColorGroupTransparent_11: variables(part).greyDarkTransparentColor,
    officeVolumesColorGroup_12: variables(part).blueColor,
    officeVolumesColorGroupTransparent_12: variables(part).blueColorTransparent,
    officeVolumesColorGroup_13: variables(part).blueColor,
    officeVolumesColorGroupTransparent_13: variables(part).blueColorTransparent

})

export const main = {
    ...variables('main'),
    ...chartStatsColors('main'),
    ...officeVolumesColor('main'),
    logoSize: {
        desktop: {
            width: '134',
            height: '30'
        },
        mobile: {
            width: '113',
            height: '24'
        },
        app: {
            width: '243',
            height: '52'
        }
    },
    containerWidth: '1704px',
    containerWidthMain: '1565px',
    menuLinkFontWeight: '500',
    authPanelPadding: '44px 40px 48px 40px',

    //Transitions
    transition: '0.1s all linear'

};

export const office = {
    officePage: true,
    ...variables('office'),
    ...chartStatsColors('office'),
    ...officeVolumesColor('office')
};

export const admin = {
    adminPage: true,
    ...variables('admin'),
    ...chartStatsColors('admin'),
    ...officeVolumesColor('admin'),
    logo: {
        size: {
            width: '134',
            height: '30'
        },
        fill: variables('admin').blueColor
    }
};